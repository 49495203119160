import React from "react";
import * as styles from "./review.module.css";
import myImg from "../../../images/sliderb.jpg";

const Reviews = () => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.card}>
        <div className={styles.imgWrap}>
          <img src={myImg} alt="Reviews" className={styles.pht} />
          <div className={styles.userName}>Hamza Liaqat</div>
        </div>
        <div className={styles.rating}>★★★★★</div>
        <div className={styles.title}>
          <h3>Willow does some seriously great work!</h3>
        </div>
        <div className={styles.description}>
          <p>
            Every time I go see Willow, she's done something more and more
            incredible. There's a reason she's the Grand Codemaster, everything
            she touches turns to code.. err.. I mean gold! Talk about the 'Midas
            Touch'!
          </p>
        </div>
        <div className={styles.bottom}>
          <div className="date">December 31, 2020</div>
        </div>
      </div>

      <div className={styles.card}>
        <div className={styles.imgWrap}>
          <img src={myImg} alt="Reviews" className={styles.pht} />
          <div className={styles.userName}>Hamza Liaqat</div>
        </div>
        <div className={styles.rating}>★★★★★</div>
        <div className={styles.title}>
          <h3>Willow does some seriously great work!</h3>
        </div>
        <div className={styles.description}>
          <p>
            Every time I go see Willow, she's done something more and more
            incredible. There's a reason she's the Grand Codemaster, everything
            she touches turns to code.. err.. I mean gold! Talk about the 'Midas
            Touch'!
          </p>
        </div>
        <div className={styles.bottom}>
          <div className="date">December 31, 2020</div>
        </div>
      </div>

      <div className={styles.card}>
        <div className={styles.imgWrap}>
          <img src={myImg} alt="Reviews" className={styles.pht} />
          <div className={styles.userName}>Hamza Liaqat</div>
        </div>
        <div className={styles.rating}>★★★★★</div>
        <div className={styles.title}>
          <h3>Willow does some seriously great work!</h3>
        </div>
        <div className={styles.description}>
          <p>
            Every time I go see Willow, she's done something more and more
            incredible. There's a reason she's the Grand Codemaster, everything
            she touches turns to code.. err.. I mean gold! Talk about the 'Midas
            Touch'!
          </p>
        </div>
        <div className={styles.bottom}>
          <div className="date">December 31, 2020</div>
        </div>
      </div>

      <div className={styles.card}>
        <div className={styles.imgWrap}>
          <img src={myImg} alt="Reviews" className={styles.pht} />
          <div className={styles.userName}>Hamza Liaqat</div>
        </div>
        <div className={styles.rating}>★★★★★</div>
        <div className={styles.title}>
          <h3>Willow does some seriously great work!</h3>
        </div>
        <div className={styles.description}>
          <p>
            Every time I go see Willow, she's done something more and more
            incredible. There's a reason she's the Grand Codemaster, everything
            she touches turns to code.. err.. I mean gold! Talk about the 'Midas
            Touch'!
          </p>
        </div>
        <div className={styles.bottom}>
          <div className="date">December 31, 2020</div>
        </div>
      </div>

      <div className={styles.card}>
        <div className={styles.imgWrap}>
          <img src={myImg} alt="Reviews" className={styles.pht} />
          <div className={styles.userName}>Hamza Liaqat</div>
        </div>
        <div className={styles.rating}>★★★★★</div>
        <div className={styles.title}>
          <h3>Willow does some seriously great work!</h3>
        </div>
        <div className={styles.description}>
          <p>
            Every time I go see Willow, she's done something more and more
            incredible. There's a reason she's the Grand Codemaster, everything
            she touches turns to code.. err.. I mean gold! Talk about the 'Midas
            Touch'!
          </p>
        </div>
        <div className={styles.bottom}>
          <div className="date">December 31, 2020</div>
        </div>
      </div>

      <div className={styles.card}>
        <div className={styles.imgWrap}>
          <img src={myImg} alt="Reviews" className={styles.pht} />
          <div className={styles.userName}>Hamza Liaqat</div>
        </div>
        <div className={styles.rating}>★★★★★</div>
        <div className={styles.title}>
          <h3>Willow does some seriously great work!</h3>
        </div>
        <div className={styles.description}>
          <p>
            Every time I go see Willow, she's done something more and more
            incredible. There's a reason she's the Grand Codemaster, everything
            she touches turns to code.. err.. I mean gold! Talk about the 'Midas
            Touch'!
          </p>
        </div>
        <div className={styles.bottom}>
          <div className="date">December 31, 2020</div>
        </div>
      </div>

      <div className={styles.card}>
        <div className={styles.imgWrap}>
          <img src={myImg} alt="Reviews" className={styles.pht} />
          <div className={styles.userName}>Hamza Liaqat</div>
        </div>
        <div className={styles.rating}>★★★★★</div>
        <div className={styles.title}>
          <h3>Willow does some seriously great work!</h3>
        </div>
        <div className={styles.description}>
          <p>
            Every time I go see Willow, she's done something more and more
            incredible. There's a reason she's the Grand Codemaster, everything
            she touches turns to code.. err.. I mean gold! Talk about the 'Midas
            Touch'!
          </p>
        </div>
        <div className={styles.bottom}>
          <div className="date">December 31, 2020</div>
        </div>
      </div>

      <div className={styles.card}>
        <div className={styles.imgWrap}>
          <img src={myImg} alt="Reviews" className={styles.pht} />
          <div className={styles.userName}>Hamza Liaqat</div>
        </div>
        <div className={styles.rating}>★★★★★</div>
        <div className={styles.title}>
          <h3>Willow does some seriously great work!</h3>
        </div>
        <div className={styles.description}>
          <p>
            Every time I go see Willow, she's done something more and more
            incredible. There's a reason she's the Grand Codemaster, everything
            she touches turns to code.. err.. I mean gold! Talk about the 'Midas
            Touch'!
          </p>
        </div>
        <div className={styles.bottom}>
          <div className="date">December 31, 2020</div>
        </div>
      </div>

      <div className={styles.card}>
        <div className={styles.imgWrap}>
          <img src={myImg} alt="Reviews" className={styles.pht} />
          <div className={styles.userName}>Hamza Liaqat</div>
        </div>
        <div className={styles.rating}>★★★★★</div>
        <div className={styles.title}>
          <h3>Willow does some seriously great work!</h3>
        </div>
        <div className={styles.description}>
          <p>
            Every time I go see Willow, she's done something more and more
            incredible. There's a reason she's the Grand Codemaster, everything
            she touches turns to code.. err.. I mean gold! Talk about the 'Midas
            Touch'!
          </p>
        </div>
        <div className={styles.bottom}>
          <div className="date">December 31, 2020</div>
        </div>
      </div>
    </div>
  );
};

export default Reviews;
