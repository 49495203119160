import React from "react";
import Index from "../components/reviews";

const reviews = () => {
  return (
    <>
      <Index />
    </>
  );
};

export default reviews;
