import React from "react";
import Footer from "../home/footer/Footer";
import Navbar from "../home/navbar/Navbar";
import Reviews from "./review/Reviews";
import ReviewsHead from "./header/ReviewsHead";

const Index = () => {
  return (
    <div>
      <Navbar />
      <ReviewsHead />
      <Reviews />
      <Footer />
    </div>
  );
};

export default Index;
